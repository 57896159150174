import React from 'react';
import { Link } from 'gatsby';
import Layout from '../containers/Layout';
import Hero from '../containers/Hero';
import Section from '../components/Section';
import Label from '../components/Label';
import styled from 'styled-components';
import { ColumnContainer, Column, ColumnTitle, ColumnText } from '../components/Columns';
import Button from '../components/Button';
import Title from '../components/Title';
import Image from '../components/Image';
import Caption from '../components/Caption';

const ButtonLink = Button.withComponent(Link);

const ButtonWithMargin = styled(ButtonLink)`
  margin: 0 3rem 1rem 0;

  &:last-of-type {
    margin-right: 0;
  }
`;

const ButtonContainer = styled.div`
  margin: 2rem 0;
`;

export default () => (
  <Layout>
    <Hero secondary>
      <Label>About</Label>
      <Title>Get to know me</Title>
    </Hero>
    <Section separator>
      <ColumnContainer>
        <Column>
          <Caption>
            My name is Tomasz Gil. 👋 I’m a software engineer focused on problem solving, lifelong
            learner based in Poznań, Poland.
          </Caption>
          <p>
            I graduated from Poznań University of Technology with both an Engineer and Master's
            degree in Computer Science. During that time, I actively participated in{' '}
            <a href="https://akai.org.pl" target="_blank" rel="noopener noreferrer">
              AKAI
            </a>{' '}
            - a students organization around web technologies, creating applications, sharing
            knowledge and being a part of the core of the organization.
          </p>
          <p>
            I had the privilege of working directly with brands like{' '}
            <a href="https://revofund.com" target="_blank" rel="noopener noreferrer">
              Revofund
            </a>{' '}
            or{' '}
            <a href="https://masterhub.com" target="_blank" rel="noopener noreferrer">
              Masterhub
            </a>
            , designing and creating beautiful, intuitive and functional experiences. At{' '}
            <a href="https://www.egnyte.com/" target="_blank" rel="noopener noreferrer">
              Egnyte
            </a>
            , I worked on a web application used by over 17,000 businesses worldwide, which allows
            to store, manage and collaborate on files. I was involved in a complete redesign of the
            system, creating a new file previewer, file co-editing mode and many other projects. At{' '}
            <a href="https://www.rvvup.com/" target="_blank" rel="noopener noreferrer">
              Rvvup
            </a>
            , I created an advanced payments platform for e-commerce merchants. I was involved in
            integrating multiple payment methods, implementing merchant dashboard data
            visualizations, introducing dark mode to the UI, and many others.
          </p>
          <p>
            I'm currently working remotely at{' '}
            <a href="https://www.salesloft.com/" target="_blank" rel="noopener noreferrer">
              Salesloft
            </a>{' '}
            as a Senior UI Engineer.
          </p>
          <ButtonContainer>
            <ButtonWithMargin as={Link} to="/projects">
              See my projects
            </ButtonWithMargin>
            <ButtonWithMargin
              as="a"
              href="https://blog.tomaszgil.me"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read my articles
            </ButtonWithMargin>
          </ButtonContainer>
        </Column>
        <Column>
          <Image filename="square-photo.jpg" />
        </Column>
      </ColumnContainer>
    </Section>
    <Section>
      <ColumnContainer>
        <Column>
          <ColumnTitle>Skills</ColumnTitle>
          <ul>
            <li>Front-end web development</li>
            <li>Automated testing</li>
            <li>User interface design</li>
          </ul>
        </Column>
        <Column>
          <ColumnTitle>Technologies</ColumnTitle>
          <ul>
            <li>HTML, CSS (SASS)</li>
            <li>JavaScript, TypeScript</li>
            <li>React.js, Redux, Remix, Gatsby.js</li>
            <li>Styled Components, Stitches, Tailwind CSS</li>
            <li>Jest, React Testing Library, Cypress</li>
            <li>GraphQL, Node.js</li>
          </ul>
        </Column>
        <Column>
          <ColumnTitle>Passions</ColumnTitle>
          <ColumnText>
            Deeply interested in web design and web technologies. Self-taught guitar player and
            fragrance enthusiast. Always seeking for ways to improve.
          </ColumnText>
        </Column>
      </ColumnContainer>
    </Section>
  </Layout>
);
